/*!---------- Custom Styles Here ----------*/

body {
  font-size: 17px;
}

p,
span {
  font-weight: 300;
}

.ribbon.mela-heading {
  background: #25262e;
  display: inline-block;
  padding: 0 12px 0 24px;
  text-align: center;
  position: relative;
  height: 36px;
}

.ribbon.mela-heading:after {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  border: 18px solid #25262e;
  z-index: -1;
  right: -36px;
  border-left-width: 24px;
  border-right-color: transparent;
  -webkit-box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0px;
  -moz-box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0px;
}

.ribbon.mela-heading h1,
.ribbon.mela-heading h2 {
  font-size: 22px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 5px;
  margin-right: -3px;
}

@media all and (max-width: 767px) {
  .ribbon.mela-heading h1.home-heading {
    font-size: 19px;
    letter-spacing: 2px;
  }
}

/* Footer */
footer .image-small.mela-small-logo {
  margin-right: 20px;
  top: inherit;
  bottom: -17px;
}

.mela-hidden {
  display: none;
}

.nav-bar.nav-open {
  max-height: 10000px !important;
}

.cake-heading {
  font-size: 18px;
  font-weight: 500;
  padding-top: 3px;
}

.social-contact {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  br {
    display: initial;
  }
}

.cta {
  background: rgb(210, 210, 210);
  padding: 20px 15px;
  border-radius: 5px;
  text-align: center;
}

.cta-header {
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.home-bg {
  background-color: #fff;
  padding: 50px 0;
  position: relative;
  z-index: 1;
}

.home-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index: -1;
  /* background: url(/img/test-mela-pattern@2x.png); */
}

.contact-details a {
  font-weight: 300;
}
